import React from "react";
import { useNavigate, Link } from "react-router-dom";
import Modal from 'react-modal';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

//const URL = "https://goss.classroomgo.org";
const URL = "https://goss.classroomgo.org";

class Home extends React.Component {

    constructor(props) {
        super(props);

        let tab = 0;

        try {
            let active_tab = localStorage.getItem("active_tab");
            if(active_tab) {
                console.log('11111 ' + active_tab)
                tab = parseInt(active_tab);
            } 
        } catch(e) {
            console.error(e);
        }

        this.state = {
            is_logged_on: false,
            currentTab: tab,
            teacher_list: [],
            teacher_student_list: [],
            student_list: [],
            broadcast_presenter_list: [],
            broadcast_receiver_list: [],
            log_details_list: [],
            show_teacher_popup: false,
            show_broadcast_popup: false,
            username: "",
            password: "",
        }

        this.selectedRow = {
            email: "123",
            online_count: 0,
            offline_count: 0
        }

        this.customStyles = {
            overlay: { background: "rgba(18, 18, 18, 0.8)" },
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              transform: 'translate(-50%, -50%)',
              width: "60%",
              height: "70%",
              backgroundColor: "#FFFFFF",
              padding: "2px",
            },
        };

        this.my_teacher_list = {};
        this.my_teacher_student_list = {};
        this.my_student_list = {};

        this.my_broadcast_presenter_list = {};
        this.my_broadcast_receiver_list = {};
        this.my_log_details_list = {};
        
        this.loadDetails();

    }

    loadDetails = async () => {

        try {

            let active_tab = localStorage.getItem("active_tab");

            console.log('loadDetails active_tab ' + active_tab);

            if(!active_tab || active_tab == "0") {
                this.loadTeacherDetails();
            } else if(active_tab == "1") {
                this.loadStudentDetails();
            } else if(active_tab == "2") {
                this.loadBroadcastDetails();
            } else if(active_tab == "3") {
                this.loadLogDetails();
            }

        } catch(e) {
            console.error(e);
        }
        
    }
    
    loadTeacherDetails = async () => {

        try {

            let fromDate = document.getElementById("fromdate");
            let toDate = document.getElementById("todate");
            let email = document.getElementById("email_filter");

            console.log('[loadTeacherDetails] fromDate ' + fromDate + " , toDate " + toDate + ' , email ' + email);
            
            let params = "";
            
            if(fromDate) {
                
                if(params == "") {
                    params = "from_date=" + fromDate.value;
                } else {
                    params = params + "&from_date=" + fromDate.value;
                }

            }

            if(toDate) {
                
                if(params == "") {
                    params = "to_date=" + toDate.value;
                } else {
                    params = params + "&to_date=" + toDate.value;
                }

            }

            if(email) {
                
                if(params == "") {
                    params = "email=" + email.value;
                } else {
                    params = params + "&email=" + email.value;
                }

            }

            let url = URL + "/teacher_details";
            
            if(params != "") {
                url = url + "?" + params;
            }

            console.log('loadTeacherDetails Query ' + url);

            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            let json = await res.json();
            console.dir(json);

            this.my_teacher_list = [];
            

            for(let i = 0; i < json.length; i ++) {
                let json_obj = json[i];
                this.my_teacher_list.push(json_obj)
            }
            
            this.setState({teacher_list : this.my_teacher_list});

        } catch(e) {
            console.error(e);
        }
    
    }

    loadStudentDetails = async () => {

        try {

            let fromDate = document.getElementById("fromdate");
            let toDate = document.getElementById("todate");
            let email = document.getElementById("email_filter");

            console.log('[loadStudentDetails] fromDate ' + fromDate + " , toDate " + toDate + ' , email ' + email);
            
            let params = "";
            
            if(fromDate) {
                
                if(params == "") {
                    params = "from_date=" + fromDate.value;
                } else {
                    params = params + "&from_date=" + fromDate.value;
                }

            }

            if(toDate) {
                
                if(params == "") {
                    params = "to_date=" + toDate.value;
                } else {
                    params = params + "&to_date=" + toDate.value;
                }

            }

            if(email) {
                
                if(params == "") {
                    params = "email=" + email.value;
                } else {
                    params = params + "&email=" + email.value;
                }

            }

            let url = URL + "/student_details";
            
            if(params != "") {
                url = url + "?" + params;
            }

            console.log('loadStudentDetails Query ' + url);

            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            let json = await res.json();
            console.dir(json);

            this.my_student_list = [];
            
            for(let i = 0; i < json.length; i ++) {
                let json_obj = json[i];
                this.my_student_list.push(json_obj)
            }
            
            this.setState({student_list : this.my_student_list});

        } catch(e) {
            console.error(e);
        }
    
    }

    loadBroadcastDetails = async () => {

        try {

            let fromDate = document.getElementById("fromdate");
            let toDate = document.getElementById("todate");
            let email = document.getElementById("email_filter");

            console.log('[loadBroadcastDetails] fromDate ' + fromDate + " , toDate " + toDate + ' , email ' + email);
            
            let params = "";
            
            if(fromDate) {
                
                if(params == "") {
                    params = "from_date=" + fromDate.value;
                } else {
                    params = params + "&from_date=" + fromDate.value;
                }

            }

            if(toDate) {
                
                if(params == "") {
                    params = "to_date=" + toDate.value;
                } else {
                    params = params + "&to_date=" + toDate.value;
                }

            }

            if(email) {
                
                if(params == "") {
                    params = "email=" + email.value;
                } else {
                    params = params + "&email=" + email.value;
                }

            }

            let url = URL + "/broadcast_presenter_details";
            
            if(params != "") {
                url = url + "?" + params;
            }

            console.log('loadBroadcastDetails Query ' + url);

            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            let json = await res.json();
            console.dir(json);

            this.my_broadcast_presenter_list = [];
            

            for(let i = 0; i < json.length; i ++) {
                let json_obj = json[i];
                this.my_broadcast_presenter_list.push(json_obj)
            }
            
            this.setState({broadcast_presenter_list : this.my_broadcast_presenter_list});

        } catch(e) {
            console.error(e);
        }
    
    }

    loadLogDetails = async () => {

        try {

            let email = document.getElementById("email_filter");

            console.log('[loadLogDetails] email ' + email);
            
            let params = "";
            
            if(email) {
                
                if(params == "") {
                    params = "email=" + email.value;
                } else {
                    params = params + "&email=" + email.value;
                }

            }

            let url = URL + "/log_details";
            
            if(params != "") {
                url = url + "?" + params;
            }

            console.log('loadLogDetails Query ' + url);

            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            let json = await res.json();
            console.dir(json);

            this.my_log_details_list = [];
            

            for(let i = 0; i < json.length; i ++) {
                let json_obj = json[i];
                this.my_log_details_list.push(json_obj)
            }
            
            this.setState({log_details_list : this.my_log_details_list});

        } catch(e) {
            console.error(e);
        }
    
    }


    onClickDateSearch = async () => {

        try {

            this.loadDetails();

        } catch(e) {
            console.error(e);
        }
    
    }

    clearDateSearch = async () => {

        try {

            let fromDate = document.getElementById("fromdate");
            let toDate = document.getElementById("todate");

            if(fromDate.value == '' && toDate.value == '') {
                console.log("[clearDateSearch] Date search is clear ----------");
                return;
            }

            fromDate.value = '';
            toDate.value = '';

            this.loadDetails();

        } catch(e) {
            console.error(e);
        }
    
    }

    onClickTeacher  = async () => {

        try {

            localStorage.setItem("active_tab", "0");

            this.setState({currentTab: 0})
            this.loadDetails();

        } catch(e) {
            console.error(e);
        }

    }

    onClickStudent = async () => {

        try {

            localStorage.setItem("active_tab", "1");
            this.setState({currentTab: 1})
            this.loadDetails();

        } catch(e) {
            console.error(e);
        }

    }

    onClickBroadcastDetails = async () => {
        
        try {

            localStorage.setItem("active_tab", "2");
            this.setState({currentTab: 2})
            this.loadDetails();

        } catch(e) {
            console.error(e);
        }

    }

    onClickLogDetails = async () => {
        
        try {

            localStorage.setItem("active_tab", "3");
            this.setState({currentTab: 3})
            this.loadDetails();

        } catch(e) {
            console.error(e);
        }
    }

    loadTeacherStudentActiveDetails = async (data) => {

        try {


            console.log('[loadTeacherStudentActiveDetails] fromDate ' + data.logged_on + " , toDate " + data.logged_off);
            
            let params = "";
            
            if(data.logged_on) {
                
                if(params == "") {
                    params = "from_date=" + data.logged_on;
                } else {
                    params = params + "&from_date=" + data.logged_on;
                }

            }

            if(data.logged_off && "" + data.is_logged_on != "1") {
                if(params == "") {
                    params = "to_date=" + data.logged_off;
                } else {
                    params = params + "&to_date=" + data.logged_off;
                }

            }


            let students = "";

            console.dir(data.students);

            for(let i = 0; i < data.students.length; i ++) {

                if(students == "") {
		    if(data.students[i].description) {
                    	students = "'" + data.students[i].description + "'";
		    } else {
			students = "'" +  data.students[i].userId + "'";;
		    }
                } else {
		    if(data.students[i].description) {
                    	students = students + ",'" + data.students[i].description + "'";
		    } else {
                    	students = students + ",'" + data.students[i].userId + "'";
		    }
                }

                console.log(' students ' + students)

            }

            if(students) {
                
                if(params == "") {
                    params = "students=" + students;
                } else {
                    params = params + "&students=" + students;
                }

            }



            let url = URL + "/teacher_student_details";
            
            if(params != "") {
                url = url + "?" + params;
            }

            console.log('loadTeacherStudentActiveDetails Query ' + url);

            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            let json = await res.json();
            console.dir(json);

            this.my_teacher_student_list = [];
            
            for(let i = 0; i < json.length; i ++) {
                let json_obj = json[i];
                this.my_teacher_student_list.push(json_obj)
            }
            
            this.setState({teacher_student_list : this.my_teacher_student_list});
            this.setState({ show_teacher_popup: true});

        } catch(e) {
            console.error(e);
        }

        
    }


    loadBroadcastReceiverDetails = async (data, history) => {

        try {

            console.log('[loadBroadcastReceiverDetails] link ' + data.link + " , history " + history);
            
            let params = "link=" + data.link;

            if(history == true) {
                params = params + "&history=true";
            }
           
            let url = URL + "/broadcast_receiver_details";
            
            if(params != "") {
                url = url + "?" + params;
            }

            console.log('loadBroadcastReceiverDetails Query ' + url);

            const res = await fetch(url, {
                method: 'GET',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            let json = await res.json();
            console.dir(json);

            this.my_broadcast_receiver_list = [];
            
            for(let i = 0; i < json.length; i ++) {
                let json_obj = json[i];
                this.my_broadcast_receiver_list.push(json_obj)
            }
            
            this.setState({broadcast_receiver_list : this.my_broadcast_receiver_list});
            this.setState({ show_broadcast_popup: true});

        } catch(e) {
            console.error(e);
        }

        
    }

    onPopUPWindowOpen = async () => {

        try {

        } catch(e) {
            console.error(e);
        }
    }

    closePopup = async () => {

        try {

            this.setState({show_teacher_popup: false});
            this.setState({show_broadcast_popup: false});
            
        } catch(e) {
            console.error(e);
        }

    }

    onClickEmailSearch = async () => {

        try {

            this.loadDetails();

        } catch(e) {
            console.error(e);
        }

    }

    onClickEmailSearchClear = async () => {

        try {

            let email = document.getElementById("email_filter");
    
            if(email.value == '') {
                console.log("[onClickEmailSearchClear] email search is clear ----------");
                return;
            }

            email.value = '';
          
            this.loadDetails();


        } catch(e) {
            console.error(e);
        }

    }

    onClickLogDownload = async (data) => {
        
        try {

            let fromDate = document.getElementById("logdate");

            let url = URL + "/download_log?email=" + data.email + '&date=' + fromDate.value;

            console.log('[onClickLogDownload] email ' + data.email);

            window.open(url);
          
        } catch(e) {
            console.error(e);
        }
    }

    onClickLogDelete = async (data) => {
        
        try {

            console.log('[onClickLogDelete] email ' + data.email);

            let url = URL + "/disable_log/" + data.email;
       
            console.log('onClickAddEmail Query ' + url);

            const res = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            this.loadDetails();

        } catch(e) {
            console.error(e);
        }
    }

    onClickAddEmail = async () => {
        
        try {

            let email = document.getElementById("add_email_filter");
            if(email.value == "") {
                return;
            }

            console.log('[onClickAddEmail] email ' + email);
  
            let url = URL + "/enable_log/" + email.value;
       
            console.log('onClickAddEmail Query ' + url);

            const res = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: { 'Content-Type': 'application/json','Accept':'application/json' }
            });

            this.loadDetails();

            email.value = "";

        } catch(e) {
            console.error(e);
        }
    }

    handleSubmit = (event) => {

        try {

            const errors = {

                username: "Invalid User",
                pass: "Invalid Password"
            };

            //Prevent page reload
            event.preventDefault();
           
            if(this.state.username == '') {

                alert("Please enter username.");
                return;
            }

            if(this.state.password == '') {

                alert("Please enter Password.");
                return;
            }

            const database = [
                {
                    username: "gtrainers.org",
                    password: "gto@2022"
                }
            ];
        
            const userData = database.find((user) => user.username === this.state.username);

            if (userData) {
                

                if(userData.password != this.state.password) {
                    alert("Invalid username/password")
                    return;
                }

                this.setState({is_logged_on: true});
                
            } else {
                alert("Invalid username/password")
            }

        }catch(e) {

            console.log("Error in handleSubmit :: "+e);
        }

    };

    render() {

        if(this.state.is_logged_on == false) {

            return (

                <div class="login-div">

                    <div class="containers" id="container">

                        <div class="form-container sign-in-container">

                            <div  class="login-form">
                                <h1 style={{color: '#3088E5', fontSize: 40}}>Sign In</h1>

                                 <input type="username" placeholder="Username" class="login-input" id="username_id" onChange={(e) => this.setState({username: e.target.value})}/>
                                <input type="password" placeholder="Password" class="login-input" id="password_id" onChange={(e) => this.setState({password: e.target.value})}/>
                                <br/>
                                <input type="submit" class="login-button" value="Sign In" onClick={this.handleSubmit}/>

                            </div>
                        </div>

                    </div>


                </div>

            )
        }

        return (
            <div>
                <div className="content-area">

                    <div id="sections_disp_area" className="content-section-tab-area">

                        <div>

                            <span id="teacher_section" className={this.state.currentTab == 0 ? "active-tab" : "inactive-tab"} onClick={this.onClickTeacher}>Teacher</span>

                            <span className="span-divider"></span>

                            <span id="student_section" className={this.state.currentTab == 1 ? "active-tab" : "inactive-tab"} onClick={this.onClickStudent}>Students</span>

                            <span className="span-divider"></span>
                            <span id="broadcast_section" className={this.state.currentTab == 2 ? "active-tab" : "inactive-tab"} onClick={this.onClickBroadcastDetails}>Broadcast Details</span>

                            <span className="span-divider"></span>
                            <span id="broadcast_section" className={this.state.currentTab == 3 ? "active-tab" : "inactive-tab"} onClick={this.onClickLogDetails}>Log Details</span>

                        </div>

                    </div>

                    <div className="divider"> </div>

                    <div className="content-section-one-area" id="search_area">

                        <div className="search-area-style" id="date_search_area">

                            <div class="from-to-date-area" style={{display: (this.state.currentTab != 3 ? "" : "none")}}>

                                <span class="from-to-text">From:</span><input type="date" id="fromdate" name="fromdate"/>
                                <span class="from-to-text">To:</span> <input type="date" id="todate" name="todate"/>

                                <button class="go-button" onClick={this.onClickDateSearch}>Go</button>
                                <button class="go-button" onClick={this.clearDateSearch}>Cancel</button>

                            </div>
                       
                            <div class="from-to-date-area" style={{display: (this.state.currentTab == 3 ? "" : "none")}}>
                                <span class="from-to-text">Log Date:</span><input type="date" id="logdate" name="logdate"/>
                                <td>&nbsp;</td>
                            </div>

                            <div>
                                <input type="text" id="email_filter" name="extension_filter" placeholder="Search Email"/>
                                <button class="go-button" onClick={this.onClickEmailSearch}>Search</button>
                                <button class="go-button" onClick={this.onClickEmailSearchClear}>Clear</button>
                            </div>


                        </div>

                    </div>

                    <div className="content-section-one-area" id="search_area" style={{display: (this.state.currentTab == 3 ? "" : "none")}}>

                        <div className="search-area-style" id="date_search_area">

                            <div>
                                <input type="text" id="add_email_filter" name="extension_filter" placeholder="Add Email"/>
                                <button class="go-button" onClick={this.onClickAddEmail}>Add</button>
                            </div>

                        </div>

                    </div>

                    <div id="teacher-disp-area" class="table-area" style={{display: (this.state.currentTab == 0 ? "" : "none")}}>

                        <div class="tableContainer">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="scrollTable">
                            <thead class="fixedHeader">
                                <tr>
                                    <th class="tablewidth">Email</th>
                                    <th class="tablewidth">Socket ID</th>
                                    <th class="tablewidth">Class</th>
                                    <th class="tablewidth">Students Count</th>
                                    <th class="tablewidth">Online/Offline</th>
                                    <th class="tablewidth">Log In Time</th>
                                    <th class="tablewidth">Log off Time</th>
                                    <th class="tablewidth">Version</th>
                                    <th style={{width: "10%"}}>&nbsp;</th>
                                    
                                </tr>
                            </thead>

                            <div class="no-data-display" style={{display: (this.state.teacher_list == 0 ? "" : "none")}}>
                                No Teachers to display.
                            </div>

                            <tbody class="scrollContent">
                                {this.state.teacher_list.map((data) => (

                                    <tr>
                                        <td class="tablewidth">{data.email}</td>
                                        <td class="tablewidth">{data.socket_id}</td>
                                        <td class="tablewidth">{data.classname}</td>
                                        <td class="tablewidth">
                                            <a href="#" onClick={() => this.loadTeacherStudentActiveDetails(data)}> {data.students_count} </a>
                                        </td>
                                        <td class="tablewidth">{data.status}</td>
                                        <td class="tablewidth">{data.logged_on ? data.logged_on.replace('T', ' ').replace('Z', '').split('.')[0] : data.logged_on}</td>
                                        <td class="tablewidth">{data.status != "Online" ? data.logged_off ? data.logged_off.replace('T', ' ').replace('Z', '').split('.')[0] : data.logged_off: ""}</td>
                                        <td class="tablewidth">{data.version}</td>
                                        <td style={{width: "10%"}}>&nbsp;</td>
                                    </tr>

                                    ))}
                            </tbody>

                            </table>
                        </div>



                    </div>

                    <div id="student-disp-area" class="table-area" style={{display: (this.state.currentTab == 1  ? "" : "none")}}>

                        <div class="tableContainer">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="scrollTable">
                            <thead class="fixedHeader">
                                <tr>
                                    <th class="tablewidth">Email</th>
                                    <th class="tablewidth">Socket ID</th>
                                    <th class="tablewidth">Online/Offline</th>
                                    <th class="tablewidth">Log In Time</th>
                                    <th class="tablewidth">Log off Time</th>
                                    <th class="tablewidth">Version</th>
                                    <th style={{width: "10%"}}>&nbsp;</th>
                                    
                                </tr>
                            </thead>

                            <div class="no-data-display" style={{display: (this.state.student_list == 0 ? "" : "none")}}>
                                No Students to display.
                            </div>

                            <tbody class="scrollContent">
                                {this.state.student_list.map((data) => (

                                    <tr>
                                        <td class="tablewidth">{data.email}</td>
                                        <td class="tablewidth">{data.socket_id}</td>
                                        <td class="tablewidth">{data.status}</td>
                                        <td class="tablewidth">{data.logged_on ? data.logged_on.replace('T', ' ').replace('Z', '').split('.')[0] : data.logged_on}</td>
                                        <td class="tablewidth">{data.status != "Online" ? data.logged_off ? data.logged_off.replace('T', ' ').replace('Z', '').split('.')[0] : data.logged_off: ""}</td>
                                        <td class="tablewidth">{data.version}</td>
                                        <td style={{width: "10%"}}>&nbsp;</td>
                                    </tr>

                                    ))}
                            </tbody>

                            </table>
                        </div>



                    </div>

                    
                    <div id="broadcast-disp-area" class="table-area" style={{display: (this.state.currentTab == 2 ? "" : "none")}}>

                        <div class="tableContainer">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="scrollTable">
                            <thead class="fixedHeader">
                                <tr>
                                    <th class="tablewidth">Email</th>
                                    <th class="tablewidth">Link</th>
                                    <th class="tablewidth">Class</th>
                                    <th class="tablewidth">Ice Status</th>
                                    <th class="tablewidth">Time</th>
                                    <th class="tablewidth">History</th>
                                    <th style={{width: "10%"}}>&nbsp;</th>
                                    
                                </tr>
                            </thead>

                            <div class="no-data-display" style={{display: (this.state.broadcast_presenter_list == 0 ? "" : "none")}}>
                                No Broadcasts to display.
                            </div>

                            <tbody class="scrollContent">
                                {this.state.broadcast_presenter_list.map((data) => (

                                    <tr>
                                        <td class="tablewidth">{data.email}</td>
                                        <td class="tablewidth">
                                            <a href="#" onClick={() => this.loadBroadcastReceiverDetails(data, false)}>{data.link}</a>
                                        </td>
                                        <td class="tablewidth">{data.classname}</td>
                                        <td class="tablewidth">{data.ice_status}</td>
                                        <td class="tablewidth">{data.inserted_on ? data.inserted_on.replace('T', ' ').replace('Z', '').split('.')[0] : data.inserted_on}</td>
                                        <td class="tablewidth">
                                            <a href="#" onClick={() => this.loadBroadcastReceiverDetails(data, true)}> Click</a>
                                        </td>
                                        <td style={{width: "10%"}}>&nbsp;</td>
                                    </tr>

                                    ))}
                            </tbody>

                            </table>
                        </div>



                    </div>


                    <div id="log-disp-area" class="table-area" style={{display: (this.state.currentTab == 3 ? "" : "none")}}>

                        <div class="tableContainer">
                            <table border="0" cellpadding="0" cellspacing="0" width="100%" class="scrollTable">
                            <thead class="fixedHeader">
                                <tr>
                                    <th class="tablewidth">Email</th>
                                    <th class="tablewidth">Added On</th>
                                    <th class="tablewidth">Download</th>
                                    <th class="tablewidth">Delete</th>
                                    <th style={{width: "10%"}}>&nbsp;</th>
                                </tr>
                            </thead>

                            <div class="no-log-data-display" style={{display: (this.state.log_details_list == 0 ? "" : "none")}}>
                                No Log details to display.
                            </div>

                            <tbody class="scrollContent">
                                {this.state.log_details_list.map((data) => (

                                    <tr>
                                        <td class="tablewidth">{data.email}</td>
                                        <td class="tablewidth">{data.inserted_on ? data.inserted_on.replace('T', ' ').replace('Z', '').split('.')[0] : data.inserted_on}</td>
                                        <td class="tablewidth">
                                            <a href="#" onClick={() => this.onClickLogDownload(data)}>Download</a>
                                        </td>
                                        <td class="tablewidth">
                                            <a href="#" onClick={() => this.onClickLogDelete(data)}>Delete</a>
                                        </td>
                                        <td style={{width: "10%"}}>&nbsp;</td>
                                    </tr>

                                    ))}
                            </tbody>

                            </table>
                        </div>

                    </div>

                    <Modal
                        isOpen={this.state.show_teacher_popup }
                        onAfterOpen={this.onPopUPWindowOpen(this.selectedId)}
                        onRequestClose={this.closePopup}
                        style={this.customStyles}
                    >

                        <div style={{display: "none"}} id={'popup_loading_icon_'+ this.selectedRow.email} class="popup-data-loader"></div>
                        <div id="popup_content_area" style={{display: ""}}>

                            <div>

                                <div id="cdr-popup-disp-area" class="table-area" style={{margin: "0px", height: "531px"}}>

                                    <div class="tableContainer">

                                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                            <thead class="fixedHeader">
                                                <tr>
                                                    <th class="tablewidth">Email</th>
                                                    <th class="tablewidth">Socket ID</th>
                                                    <th class="tablewidth">Online/Offline</th>
                                                    <th class="tablewidth">Log In Time</th>
                                                    <th class="tablewidth">Log off Time</th>
                                                    <th class="tablewidth">Version</th>
                                                    <th style={{width: "10%"}}>&nbsp;</th>
                                                </tr>
                                            </thead>

                                        <div class="no-data-display" style={{display: (this.state.teacher_student_list.length == 0 ? "" : "none")}}>
                                            No Data to display.
                                        </div>

                                        <tbody class="scrollContent" style={{height: "calc(100vh - 460px)"}}>
                                            {this.state.teacher_student_list.map((data) => (

                                                <tr>
                                                    <td class="tablewidth">{data.email}</td>
                                                    <td class="tablewidth">{data.socket_id}</td>
                                                    <td class="tablewidth">{data.status}</td>
                                                    <td class="tablewidth">{data.logged_on ? data.logged_on.replace('T', ' ').replace('Z', '').split('.')[0] : data.logged_on}</td>
                                                    <td class="tablewidth">{data.status != "Online" ? data.logged_off ? data.logged_off.replace('T', ' ').replace('Z', '').split('.')[0] : data.logged_off: ""}</td>
                                                    <td class="tablewidth">{data.version}</td>
                                                    <td style={{width: "10%"}}>&nbsp;</td>
                                                </tr>

                                                ))}
                                        </tbody>

                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </Modal>

                    <Modal
                        isOpen={this.state.show_broadcast_popup }
                        onAfterOpen={this.onPopUPWindowOpen(this.selectedId)}
                        onRequestClose={this.closePopup}
                        style={this.customStyles}
                    >

                        <div style={{display: "none"}} id={'popup_loading_icon_'+ this.selectedRow.email} class="popup-data-loader"></div>
                        <div id="popup_content_area" style={{display: ""}}>

                            
                            <div>

                                <div id="cdr-popup-disp-area" class="table-area" style={{margin: "0px", height: "531px"}}>

                                    <div class="tableContainer">

                                        <table border="0" cellpadding="0" cellspacing="0" width="100%">
                                            <thead class="fixedHeader">
                                                <tr>
                                                    <th class="tablewidth">Email</th>
                                                    <th class="tablewidth">Link</th>
                                                    <th class="tablewidth">Ice Status</th>
                                                    <th class="tablewidth">Time</th>
                                                    <th style={{width: "10%"}}>&nbsp;</th>
                                                </tr>
                                            </thead>

                                        <div class="no-data-display" style={{display: (this.state.broadcast_receiver_list.length == 0 ? "" : "none")}}>
                                            No Data to display.
                                        </div>

                                        <tbody class="scrollContent" style={{height: "calc(100vh - 460px)"}}>
                                            {this.state.broadcast_receiver_list.map((data) => (

                                                    <tr>
                                                        <td class="tablewidth">{data.email}</td>
                                                        <td class="tablewidth">{data.link}</td>
                                                        <td class="tablewidth">{data.ice_status}</td>
                                                        <td class="tablewidth">{data.inserted_on ? data.inserted_on.replace('T', ' ').replace('Z', '').split('.')[0] : data.inserted_on}</td>
                                                        <td style={{width: "10%"}}>&nbsp;</td>
                                                    </tr>
                                                ))}
                                        </tbody>

                                        </table>
                                    </div>

                                </div>

                            </div>

                        </div>


                    </Modal>


                </div>

            </div>
        )
    }
}

export default Home;
