import Home from './components/Home';
import Login from './components/Login';

import React, { useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import "./css/home.css";
import "./css/login.css";

function App() {
  return (
    <Router>
    <Routes>
          <Route path= "/" element={<Home/>} />
    </Routes>
  </Router>
  );
}

export default App;