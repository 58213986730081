import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Login() {

    const navigate = useNavigate();

    const [errorMessages, setErrorMessages] = useState({});
    //const [isSubmitted, setIsSubmitted] = useState(false);

    const [username, setusername] = useState("");
    const [password, setpassword] = useState("");
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

    const database = [
        {
            username: "user1@www",
            password: "pass1"
        },
        {
            username: "user2@www",
            password: "pass2"
        }
    ];

    const errors = {

        username: "Invalid User",
        pass: "Invalid Password"
    };


    const handleSubmit = (event) => {

        try {

            //Prevent page reload
            event.preventDefault();

            var { username_id, password_id} = document.forms[0];

            if(username == '') {

                alert("Please enter username.");
                username_id.focus();
                return;
            }

            if(password == '') {

                alert("Please enter Password.");
                password_id.focus();
                return;
            }

            const userData = database.find((user) => user.username === username);

            if (userData) {

                if (userData.password !== password) {

                    setErrorMessages({ name: "error_password", message: errors.pass });
                } else {

                    //setIsSubmitted(true);
                    setauthenticated(true)
                    localStorage.setItem("authenticated", true);
                    localStorage.setItem("LOGIN_USER", username);

                    navigate("/home");

                }
            } else {

                setErrorMessages({ name: "error_username", message: errors.username });
            }

        }catch(e) {

            console.log("Error in handleSubmit :: "+e);
        }

    };

    const renderErrorMessage = (name) =>

        name === errorMessages.name && (

        <div className="error">{errorMessages.message}</div>
    );


        return (

            <div class="login-div">

                <div class="containers" id="container">

                    <div class="form-container sign-in-container">

                        <form onSubmit={handleSubmit} class="login-form">
                            <h1 style={{color: '#3088E5', fontSize: 40}}>Sign In</h1>

                            {renderErrorMessage("error_username")}
                            {renderErrorMessage("error_password")}

                            <input type="username" placeholder="Username" class="login-input" name="username_id" onChange={(e) => setusername(e.target.value)} value={username}/>
                            <input type="password" placeholder="Password" class="login-input" name="password_id" onChange={(e) => setpassword(e.target.value)}/>
                            <br/>
                            <input type="submit" class="login-button" value="Sign In"/>

                        </form>
                    </div>

                </div>


            </div>

        )
    };


export default Login;